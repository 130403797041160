import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import PrimaryCta from "../components/primary-cta"
import DiscoverCta from "../components/discover-cta"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Map from "../components/map"
import ImageGallery from 'react-image-gallery';

import {numberWithSpaces} from "../utils"

class Annonce extends Component {
  render() {
    const {
      titre,
      prix,
      subtitle,
      ville,
      description,
      vueGlobale,
      asavoir,
      copropriete,
      equipement,
      telephone,
      images,
      type,
      location,
      codePostal,
      note,
      slug,
    } = this.props.data.contentfulAnnonce

    const parentPage = this.props.data.allContentfulPageAnnonces.edges.find(e => e.node.type[0] === type);
    const imagesReformatted = images.map((image) => ({original: image.url, thumbnail: image.url, description: image.description}))

    return (
      <Layout>
        <PageHeader title={parentPage.node.titre} body=""/>
        <div id="page-annonce">
          <div id="page-annonce--header">
            <div id="page-annonce--header-item" className="item-left">
              <span className="page-annonce--header-title">{titre}</span>
              <span className="page-annonce--header-subtitle">{subtitle}</span>
              <span className="page-annonce--header-location">{ville}{codePostal && " - " + codePostal}</span>
            </div>
            <div id="page-annonce--header-item" className="item-right">
              <span className="page-annonce--header-prix">{numberWithSpaces(prix)} €</span>
              {
                note && (
                  <>
                    <span className="page-annonce--header-honoraires">{note}</span>
                  </>
                )
              }
            </div>
          </div>
          <div id="page-annonce--img">
            <ImageGallery items={imagesReformatted} showPlayButton={false} showNav={false}/>
          </div>
          <div id="page-annonce--cta">
            <PrimaryCta text="Envoyer un message" link={"/contact"} state={{slug: slug}}/>
            <DiscoverCta message={"Appeler l'agence"} hiddenMessage={telephone} />
          </div>
          <div id="page-annonce--description" className="page-annonce--card">
            <span className="page-annonce--card-title">
              Description
            </span>
            <div>{description && renderRichText(description)}</div>
          </div>
          <div id="page-annonce--details">
            <div className="page-annonce--card">
              <span className="page-annonce--card-title">Vue Globale</span>
              <div>{vueGlobale && renderRichText(vueGlobale)}</div>
            </div>
            <div className="page-annonce--card">
              <span className="page-annonce--card-title">Équipement</span>
              <div>{equipement && renderRichText(equipement)}</div>
            </div>
            <div className="page-annonce--card">
              <span className="page-annonce--card-title">Copropriété</span>
              <div>{copropriete && renderRichText(copropriete)}</div>
            </div>
            <div className="page-annonce--card">
              <span className="page-annonce--card-title">À savoir</span>
              <div>{asavoir && renderRichText(asavoir)}</div>
            </div>
          </div>
          <div id="page-annonce--map">
            <Map latitude={location.lat} longitude={location.lon} zoom={15}/>
          </div>
        </div>
      </Layout>
    )
  }
}

Annonce.propTypes = {
  data: PropTypes.object.isRequired
}

export default Annonce

export const annonceQuery = graphql`
  query annonceQuery($slug: String!) {
    contentfulAnnonce(slug: { eq: $slug }) {
      slug
      titre
      type
      prix
      subtitle
      ville
      codePostal
      telephone
      note
      location {
        lat
        lon
      }
      description {
        raw
      }
      copropriete {
        raw
      }
      asavoir {
        raw
      }
      equipement {
        raw
      }
      vueGlobale {
        raw
      }
      images {
        description
        url
      }
    }
    allContentfulPageAnnonces {
      edges {
        node {
          slug
          type
          titre
        }
      }
    }
  }
`
